import 'bootstrap3';
import 'angular-ui-bootstrap';
import 'oclazyload';

import WebFont from 'webfontloader';

import './assets/css/font-awesome.min.css';
import './assets/css/pickaday.css';

// Leaving require so I don't have to ensure cjs/esm interop now
// eslint-disable-next-line global-require
const underscore = require('underscore');

export {underscore};
window._ = underscore;

WebFont.load({
	google: {
		families: ['Droid Sans', 'Droid Serif'],
	},
});
